<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>劳作方式</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <div v-auth="'plantWay-add'">
        <el-button type="primary" size="small" @click="addPlantWay"
          >新增劳作方式</el-button
        >
        &nbsp;
      </div>
      <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
        v-loading="spinning"
        :data="data"
        row-key="id"
      >
        <el-table-column prop="way" label="劳作方式"> </el-table-column>
        <el-table-column
          fixed="right"
          width="200"
          label="操作"
          align="center"
          v-if="authVerify.verify('plantWay-edit,plantWay-delete')"
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit-outline"
              size="mini"
              v-auth="'plantWay-edit'"
              @click="editPlantWay(scope.row)"
              >编辑
            </el-button>
            <el-button
              icon="el-icon-delete"
              size="mini"
              v-auth="'plantWay-delete'"
              @click="deletePlantWay(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40]"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
        :total="totalCount"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
          title="新增劳作方式"
          width="400"
          placement="right"
          :closable="false"
          :visible="addPlantWayVisible"
          @close="addPlantWayVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="plantWay"
              ref="plantWayRefFormAdd"
              :rules="plantWayRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="劳作方式" prop="way">
                <a-input v-model="plantWay.way" allowClear placeholder="请输入劳作方式"/>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addPlantWayVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddPlantWay"> 确认 </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
          title="编辑劳作方式"
          width="400"
          placement="right"
          :closable="false"
          :visible="editPlantWayVisible"
          @close="editPlantWayVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="plantWay"
              ref="plantWayRefFormEdit"
              :rules="plantWayRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="劳作方式" prop="way">
                <a-input v-model="plantWay.way" allowClear placeholder="请输入劳作方式"/>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editPlantWayVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditPlantWay"> 确认 </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PlantWay",
  data() {
    return {
      addPlantWayVisible: false,
      editPlantWayVisible: false,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      spinning: true,
      data: [],
      plantWay: {
        id: "",
        way: "",
      },
      plantWayRules: {
        way: [{ required: true, message: "请输入劳作方式!", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getPlantWayData();
  },
  created() {},
  methods: {
    getPlantWayData() {
      this.http.plantWay
        .getPlantWayListPage({
          size: this.pageSize,
          current: this.currentPage,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.data = response.data.data.records;
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current;
          }
          this.spinning = false;
        });
    },
    cleanPlantWay() {
      this.plantWay = {
        id: "",
        way: "",
      };
    },
    addPlantWay() {
      this.addPlantWayVisible = true;
        this.$refs.plantWayRefFormAdd.resetFields()
      this.cleanPlantWay();
    },
    toAddPlantWay() {
      this.$refs.plantWayRefFormAdd.validate((valid) => {
        if (valid) {
          this.http.plantWay.createdPlantWay(this.plantWay).then((res) => {
            if (res.data.status == 200) {
              this.$message.success(res.data.msg);
              this.addPlantWayVisible = false;
            } else {
              this.$message.warning(res.data.msg);
            }
            this.getPlantWayData();
          });
        }
      });
    },
    toEditPlantWay() {
      this.$refs.plantWayRefFormEdit.validate((valid) => {
        if (valid) {
          this.http.plantWay.updatePlantWay(this.plantWay).then((res) => {
            if (res.data.status == 200) {
              this.$message.success(res.data.msg);
              this.editPlantWayVisible = false;
            } else {
              this.$message.warning(res.data.msg);
            }
            this.getPlantWayData();
          });
        }
      });
    },
    editPlantWay(data) {
      this.plantWay = {
        id: data.id,
        way: data.way,
      };
      this.editPlantWayVisible = true;
        this.$refs.plantWayRefFormEdd.resetFields()
    },
    deletePlantWay(data) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.plantWay
            .deletePlantWay({ id: data.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getPlantWayData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    handlePageChange(val) {
      this.spinning = true;
      this.currentPage = val;
      this.getPlantWayData();
    },
    handleSizeChange(val) {
      this.spinning = true;
      this.pageSize = val;
      this.getPlantWayData();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
